<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.in_warehouse_reservation") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row
        :gutter="[16, 16]"
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                v-model:value="searchData.planNo"
                :placeholder="$t('warehouse.reservation_number')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-input
                v-model:value="searchData.boxNoOrCanScanedBoxNo"
                :placeholder="$t('warehouse.box_id_or_scannable_box_number')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
              <a-col>
              <a-input
                v-model:value="searchData.shipmentNameOrNo  "
                :placeholder="$t('warehouse.shipment_name_or_shipment_number')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            

            <a-col>
              <a-input
                v-model:value="searchData.planName"
                :placeholder="$t('warehouse.in_warehouse_reservation_name')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-input-group compact style="width: 350px">
                <a-select
                  style="width: 40%"
                  v-model:value="searchData.transportationMethod"
                  :placeholder="$t('warehouse.type_of_shipping')"
                  allow-clear
                  @change="searchData.containerNo = null"
                >
                  <a-select-option
                    v-for="(value, key) in transportationMethodEnum"
                    :key="key"
                    :value="value"
                    :title="$t($enumLangkey('transportationMethod', value))"
                  >
                    {{ $t($enumLangkey("transportationMethod", value)) }}
                  </a-select-option>
                </a-select>
                <a-input
                  style="width: 60%"
                  v-model:value="searchData.containerNo"
                  :disabled="searchData.transportationMethod !== transportationMethodEnum.container"
                  :placeholder="$t('warehouse.container_no')"
                  allow-clear
                ></a-input>
              </a-input-group>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.transitStatus"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option
                  v-for="(value, key) in transitStatusEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('transitStatus', value))"
                >
                  {{ $t($enumLangkey("transitStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-row :gutter="8" type="flex" align="middle">
                <a-col>{{ $t('warehouse.creation_time') }}:</a-col>
                <a-col>
                  <a-range-picker
                    style="width: 350px"
                    @change="handleSelectedCreateDate"
                  />
                </a-col>
              </a-row>
            </a-col>
            <a-col>
              <a-row :gutter="8" type="flex" align="middle">
                <a-col>{{ $t('warehouse.date_of_reservation') }}:</a-col>
                <a-col>
                  <a-input-group compact style="width: 350px">
                    <a-select
                      v-model:value="searchData.warehouseId"
                      :placeholder="$t('warehouse.target_warehouse')"
                      allow-clear
                      :showSearch="true"
                      :loading="dropDownData.warehouseListLoading"
                      optionFilterProp="search-key"
                      @change="handleChangeWarehouse"
                      style="width: 40%;"
                    >
                      <a-select-option
                        v-for="(value, key) in dropDownData.warehouseList"
                        :key="key"
                        :value="value.id"
                        :title="`${value.warehouseNo}(${value.warehouseName})`"
                        :search-key="value.warehouseNo + value.warehouseName"
                        :timezone="value?.timezone"
                      >
                        {{ value.warehouseNo }}({{ value.warehouseName }})
                      </a-select-option>
                    </a-select>
                    <a-range-picker
                      v-model:value="searchData.appointmentTime"
                      :disabled="!searchData.warehouseId"
                      style="width: 60%;"
                      @change="handleSelectedDate"
                    />
                  </a-input-group>
                </a-col>
              </a-row>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="loading" @click="handleSearch">{{
                $t("common.query")
              }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <router-link :to="{ name: 'transit_in_plan_create' }">
            <a-button type="primary" ghost>{{ $t("common.create") }}</a-button>
          </router-link>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="list"
          :scroll="{ x: true, y: wrap.contentHeight - 170 }"
          :pagination="false"
          size="small"
          :loading="loading"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #planNoTitle>
            {{ $t("warehouse.in_warehouse_reservation_name") }}
            <div>
              {{ $t("warehouse.warehousing_reservation_number") }}
            </div>
          </template>
          <template #planNo="{ record }">
            {{ record.planName }}
            <div>
              <CPlanNumberItem :no="record.planNo" />
            </div>
          </template>

          <template #wareZoneAppointmentDateTitle>
            <div>
              {{ $t("warehouse.date_of_reservation") }}
            </div>
            <div>
              <a-tag color="warning">{{ $t("warehouse.local_time") }}</a-tag>
            </div>
          </template>
          <template #wareZoneAppointmentDate="{ record }">
            <div>
              {{
                $filters.utcToSpecificTime(
                  record.wareZoneAppointmentDate,
                  record.wareTimeZone
                )
              }}
            </div>
          </template>

          <template #transportationMethod="{ record }">
            {{
              $t(
                $enumLangkey(
                  "transportationMethod",
                  record.transportationMethod
                )
              )
            }}
            <template v-if="record.transportationMethod === transportationMethodEnum.container">
              <div>{{ record.containerNo || '-' }}</div>
              <div>
                <a-tag v-if="record.containerType">
                  {{
                    $t(
                      $enumLangkey(
                        "containerType",
                        record.containerType
                      )
                    )  
                  }}
                </a-tag>
                <span v-else>-</span>
              </div>
            </template>
          </template>

          <template #number_of_packages="{ record }">
            {{
              $filters.countProgressFormat(
                record?.shipmentTotalCount?.actual,
                record?.shipmentTotalCount?.plan
              )
            }}
          </template>

          <template #box_amount="{ record }">
            {{
              $filters.countProgressFormat(
                record.boxCount?.actual,
                record.boxCount?.plan
              )
            }}
          </template>

          <template #transitStatus="{ record }">
            <span
              class="text-success"
              v-if="record.transitStatus == transitStatusEnum.accomplish"
            >
              {{ $t($enumLangkey("transitStatus", record.transitStatus)) }}
            </span>
            <span
              class="text-warning"
              v-else-if="
                record.transitStatus == transitStatusEnum.partlyCompleted ||
                record.transitStatus == transitStatusEnum.overfulfil
              "
            >
              {{ $t($enumLangkey("transitStatus", record.transitStatus)) }}
            </span>
            <span
              class="text-error"
              v-else-if="
                record.transitStatus == transitStatusEnum.failureAppointment
              "
            >
              {{ $t($enumLangkey("transitStatus", record.transitStatus)) }}
            </span>
            <span v-else>
              {{ $t($enumLangkey("transitStatus", record.transitStatus)) }}
            </span>
          </template>

          <template #date>
            {{ $t("warehouse.create_date") }}
            <div>
              {{ $t("warehouse.update_date") }}
            </div>
          </template>
          <template #dateCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>
              {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
            </div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button @click="handleEditOrDetails(record)">
              <span v-if="record.canModified">{{ $t("common.edit") }}</span>
              <span v-else>{{ $t("common.details") }}</span>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-if="
                      record.transportationMethod ==
                      transportationMethodEnum.truck
                    "
                    :disabled="!record.canPrintAppointmentLetter"
                    :key="3"
                    :record="record"
                    @click="handlePrint(record)"
                  >
                    {{ $t("warehouse.print_reserve_information") }}
                  </a-menu-item>

                  <a-popconfirm
                    :title="$t('common.are_you_sure')"
                    :ok-text="$t('common.confirm')"
                    :cancel-text="$t('common.cancel')"
                    @confirm="handleCancelPlan(record)"
                  >
                    <a-menu-item key="4" :disabled="!record.cancelled">
                      {{ $t("common.cancel") }}
                    </a-menu-item>
                  </a-popconfirm>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { defineComponent, reactive, toRefs, onMounted, onActivated } from "vue";
import {
  Row,
  Col,
  Tag,
  Table,
  Input,
  Select,
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Popconfirm,
  message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";
import {
  getWarehouseList,
  getTransportPlanList,
  getPrintAppointmentLetterUrl,
} from "../../../api/modules/transit/inPlan/list";
import { cancelPlan } from "../../../api/modules/transit/inPlan/plan";
import {
  transitStatus as transitStatusEnum,
  transportationMethod as transportationMethodEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
} from "../../../enum/enum.json";
import { currentTimeToUtc } from "../../../utils/general";
import { useRouter } from "vue-router";
import localPrint from "../../../utils/localPrint";

export default defineComponent({
  name: "transit_in_plan_list",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    ATag: Tag,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    APopconfirm: Popconfirm,
    Content,
    CPager,
    CPlanNumberItem,
  },
  setup() {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        dataIndex: "warehouseNo",
        width: 150,
        title: () => vueI18n.t("warehouse.in_warehouse_code"),
      },
      {
        width: 180,
        slots: {
          title: "planNoTitle",
          customRender: "planNo",
        },
      },
      {
        width: 150,
        slots: {
          title: "wareZoneAppointmentDateTitle",
          customRender: "wareZoneAppointmentDate",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transportationMethod",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.number_of_packages"),
        slots: {
          customRender: "number_of_packages",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "transitStatus",
        },
      },
      {
        width: 150,
        slots: {
          title: "date",
          customRender: "dateCustom",
        },
      },
      {
        dataIndex: "operate",
        width: 150,
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const state = reactive({
      searchData: {
        planNo: null,
        planName: null,
        transportationMethod: null,
        containerNo: null,
        zoneAppointmentDate: null,
        beginWareZoneAppointmentDate: null,
        endWareZoneAppointmentDate: null,
        transitStatus: null,
        beginCreationTime: null,
        endCreationTime: null,
        boxNoOrCanScanedBoxNo:null,
        shipmentNameOrNo  :null,
      },
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const dropDownData = reactive({
      warehouseList: [],
      warehouseListLoading: false,
    });

    const funcGetWarehouseList = () => {
      dropDownData.warehouseListLoading = true;
      getWarehouseList({ isActive: true })
        .then(({ result }) => {
          if (result) {
            dropDownData.warehouseList = result;
          } else {
            dropDownData.warehouseList = [];
          }
          dropDownData.warehouseListLoading = false;
        })
        .catch((e) => {
          dropDownData.warehouseListLoading = false;
        });
    };

    const handleSelectedCreateDate = (e) => {
      let beginCreationTime = null;
      let endCreationTime = null;
      if (e.length === 2) {
        beginCreationTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchData.beginCreationTime = beginCreationTime;
      state.searchData.endCreationTime = endCreationTime;
    };

    const handleSelectedDate = (e) => {
      let beginWareZoneAppointmentDate = null;
      let endWareZoneAppointmentDate = null;
      if (e.length === 2) {
        beginWareZoneAppointmentDate = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
        endWareZoneAppointmentDate = currentTimeToUtc(
          e[1]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
      }
      state.searchData.beginWareZoneAppointmentDate =
        beginWareZoneAppointmentDate;
      state.searchData.endWareZoneAppointmentDate = endWareZoneAppointmentDate;
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearch();
    };

    const funcSearch = async () => {
      state.loading = true;
      getTransportPlanList({ ...state.searchData, ...state.pageData })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.list = items;
            state.pageData.totalCount = totalCount * 1;
          }
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };

    const handlePrint = (record) => {
      let pdfUrl = getPrintAppointmentLetterUrl(record.planId);
      localPrint(pdfUrl);
    };

    const handleCancelPlan = (record) => {
      record.loading = true;
      cancelPlan(record.planId)
        .then(({ result }) => {
          record.loading = false;

          message.success(vueI18n.t("common.succeed"));
          handleSearch();
        })
        .catch(() => {
          record.loading = false;
        });
    };

    const handleClickOperateMenu = (e) => {
      let { key, item } = e;
      if (key == "4") {
        return;
      }
      let { record } = item;
      if (key == 3) {
        handlePrint(record);
      }
    };

    const handleEditOrDetails = (record) => {
      //有编辑就无详情
      if (record.canModified) {
        if (record.transitInBoundStep == 1) {
          router.push({
            name: "transit_in_plan_add_shipment",
            params: { id: record.planId },
          });
        } else if (record.transitInBoundStep == 2) {
          router.push({
            name: "transit_in_plan_edit",
            params: { id: record.planId },
          });
        }
      } else {
        router.push({
          name: "transit_in_plan_detail",
          params: { id: record.planId },
        });
      }
    };

    const handleChangeWarehouse = (value, expand) => {
      state.timezone = value ? expand?.timezone : null;
      if (!value) {
        state.searchData.appointmentTime = null;
        state.searchData.beginWareZoneAppointmentDate = null;
        state.searchData.endWareZoneAppointmentDate = null;
      }
    };

    onMounted(() => {
      funcGetWarehouseList();
    });

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      ...toRefs(state),
      dropDownData,
      transitStatusEnum,
      scheduleDeliveryTypeEnum,

      handleSelectedCreateDate,
      handleSelectedDate,
      handlePage,
      handleSearch,
      handlePrint,
      handleCancelPlan,
      handleClickOperateMenu,
      handleEditOrDetails,
      transportationMethodEnum,
      handleChangeWarehouse,
    };
  },
});
</script>

<style lang="less" scoped></style>
